export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50')
];

export const server_loads = [0,4,3,6,7,8,9,2,10,11];

export const dictionary = {
		"/": [12],
		"/about": [24],
		"/addons": [25,[4]],
		"/addons/[post]": [~26,[4]],
		"/(marketing)/asana-forms": [18,[3]],
		"/blog": [~27,[5]],
		"/blog/[category=blog_cat]": [~28,[5]],
		"/blog/[slug]": [~29,[5]],
		"/case-studies": [30,[6]],
		"/case-studies/[post]": [~31,[6]],
		"/compare": [32],
		"/(marketing)/email-forms": [19,[3]],
		"/form-fields": [33,[7]],
		"/form-fields/[category=field_cat]": [34,[7,8]],
		"/form-fields/[field]": [~35,[7]],
		"/form-templates": [~36,[9]],
		"/form-templates/[template=b_test]": [~40,[9]],
		"/form-templates/[template=a_test]": [~39,[9]],
		"/form-templates/[subcategory=sub]": [~38,[9]],
		"/form-templates/[category=cat]": [~37,[9]],
		"/form-templates/[template]": [~41,[9]],
		"/(alternative)/formassembly-alternative": [13,[2]],
		"/help": [42,[10]],
		"/help/contact": [45,[10]],
		"/help/[category]": [43,[10,11]],
		"/help/[category]/[post]": [~44,[10,11]],
		"/(marketing)/hubspot-forms": [~20,[3]],
		"/(alternative)/jotform-alternative": [14,[2]],
		"/nonprofit": [46],
		"/pricing": [~47],
		"/(tools)/random-color-palette-generator": [23],
		"/(marketing)/salesforce-forms": [~21,[3]],
		"/security": [48],
		"/(alternative)/surveymonkey-alternative": [15,[2]],
		"/terms": [49],
		"/test": [50],
		"/(alternative)/typeform-alternative": [16,[2]],
		"/(alternative)/wufoo-alternative": [17,[2]],
		"/(marketing)/zendesk-ticket-forms": [~22,[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';